<template>
<v-dialog v-model="display" content-class="confirm" width="500">
    <v-card class="alert-dialog no-selection dark--text">
        <meo-meditation-player ref="player" :audio="model" :resource="resource" from-dialog></meo-meditation-player>
    </v-card>
</v-dialog>
</template>

<script>
import _ from 'lodash'

import MeoMeditationPlayer from "@/components/misc/meo-meditation-player.vue";

export default {
    props: {
        resource: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default () {
                return 'Alert'
            }
        },
        actionLabel: {
            type: String,
            default () {
                return 'OK'
            }
        },
        cancelLabel: {
            type: String,
            default () {
                return 'Cancel'
            }
        },
        actionColor: {
            type: String,
            default () {
                return 'primary'
            }
        },
        cancelColor: {
            type: String,
            default () {
                return '#EEEEEE'
            }
        },
        hideAction: Boolean,
        hideCancel: Boolean
    },

    components: {
        MeoMeditationPlayer
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            model: null,
            message: '',
            errorMessage: ''
        }
    },
    computed: {
        isValid() {
            return !this.loading
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || 'An error occured. Try again.'
            return this.message || '';
        }
    },
    methods: {
        init(model) {
            this.showError = false
            this.loading = false

            if (!model) {
                this.model = null
            } else {
                let clonedModel = _.cloneDeep(model)
                this.model = clonedModel
            }
        },
        open(model, message) {
            this.init(model)
            this.message = message
            this.display = true
        },
        error(message) {
            this.errorMessage = message
            this.showError = true
            this.loading = false
        },
        confirm() {
            this.loading = true
            this.$emit('confirm', this.model)
        },
        cancel() {
            this.$emit('cancel')
            this.close()
        },
        close() {
            this.display = false
        }
    },

    watch: {
        display(v) {
            this.$nextTick(function() {
                if (v) {
                    this.$refs.player.loadAudio();
                } else {
                    this.$refs.player.resetAudio();
                }
            });
        }
    }
}
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 100px 80px rgba(74, 34, 202, 0.07), 0 22.3363px 17.869px rgba(74, 34, 202, 0.0417275), 0 6.6501px 5.32008px rgba(74, 34, 202, 0.0282725) !important;
}

.alert-dialog {
    border-radius: 10px !important;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }

    .p-absolute {
        position: absolute !important;
    }
    .p-relative {
        position: relative !important;
    }
}
</style>
