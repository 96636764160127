<template>
<div>
    <!-- <component :is="component" /> -->
    <!-- <fluro-image class="hidden"></fluro-image> -->
    <v-runtime-template v-if="component" :template="component.options.template"></v-runtime-template>
</div>
</template>
<script>
import Vue from 'vue';
import VRuntimeTemplate from "v-runtime-template";
import FluroImage from "./meo-fluro-image.vue";
export default {
    components: {
        VRuntimeTemplate,
        FluroImage
    },
    props: {
        template: {
            type: String,
            default () {
                return '';
            },
        },
        context: {
            type: Object,
            default () {
                return {}
            }
        },
        inject: {
            type: Object,
            default () {
                return {}
            }
        },
    },
    data() {
        return {
            component: null,
        }
    },
    watch: {
        template: 'render',
        context: 'render',
        inject: 'render',
    },
    // mounted() {
    //     this.render();
    // },
    created() {
        // console.log('CREATED')
        this.render();
    },
    methods: {
        render() {
            // console.log('RENDER')
            var self = this;

            if (!self.template || !self.template.length) {
                // console.log('NO TEMPLATE');
                self.component = null;
                return;
            }

            var components;
            // console.log('PARENT', self.$parent)
            if (self.$parent && self.$parent.$options) {
                components = self.$parent.$options.components;
            }

            var dynamicComponents = Object.assign({}, components, self.inject);

            const DynamicComponent = Vue.extend({
                template: `<div>${self.template}</div>`,
                components: dynamicComponents,
                data() {
                    return self.context || {};
                }
            });

            self.component = DynamicComponent;
        }
    },
}
</script>
<style lang="scss">
.hidden {
    display: none;
}
</style>
