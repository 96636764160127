<template>
<div class="resource">
    <meo-ripple :config="config" v-if="$vuetify.breakpoint.smAndDown"></meo-ripple>

    <v-container class="text-wrap" :class="{'pa-16 py-16 pb-3' : $vuetify.breakpoint.smAndUp}">
        <v-row>
            <transition name="fade-transition" mode="out-in" appear>
                <v-container :class="[$vuetify.breakpoint.smAndDown ? 'p-fixed top20 left5' : '']">
                    <v-row>
                        <v-col cols="12">
                            <v-btn text class="zindex-2 pa-1" @click="back()">
                                <v-icon>mdi-chevron-left</v-icon>
                                Back
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </transition>

            <v-col cols="12" align="center" justify="center">
                <h3 class="heading--text">{{title}}</h3>
                <p class="muted-1 ma-0" v-if="description && description.length">{{description}}</p>
            </v-col>
        </v-row>

        <v-row v-if="audio.length">
            <v-col cols="12" class="pb-0">
                <h3>Audio</h3>
            </v-col>

            <v-col cols="12" md="4" lg="3" v-for="item in audio" :key="item._id">
                <template v-if="$vuetify.breakpoint.smAndDown">
                    <meo-list-item section :item="item" @click.native="openAudio(item)"></meo-list-item>
                </template>

                <template v-else>
                    <meo-card-item section :item="item" @click.native="openAudio(item)"></meo-card-item>
                </template>
            </v-col>
        </v-row>
    </v-container>

    <v-container class="px-0 text-wrap" :class="{'px-16' : $vuetify.breakpoint.smAndUp}" v-if="text && text.length">
        <v-row>
            <v-col cols="12" align="center" justify="center">
                <v-card class="module-section" :class="{'pa-6' : $vuetify.breakpoint.mdAndUp}">
                    <div class="text-left">
                        <v-row>
                            <v-col cols="12">
                                <!-- <div v-html="text"></div> -->
                                <meo-compile-html :template="text"></meo-compile-html>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <audio-dialog ref="audioDialog" resource @close="close"></audio-dialog>
</div>
</template>

<script>
// Mixins
import UserMixin from '@/mixins/user-mixin';
import SEOMixin from '@/mixins/seo-mixin';

// Components
import MeoRipple from "@/components/misc/meo-ripple.vue";
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";
import MeoCompileHtml from "@/components/misc/meo-compile-html.vue";
import AudioDialog from "@/components/dialogs/audio-dialog.vue";

//Services
import ResourceArticle from "@/modules/meo-module/resource-article";

export default {
    name: 'resource',
    meta: {
        titleTemplate: "Resources | %s"
    },

    components: {
        MeoRipple,
        MeoListItem,
        AudioDialog,
        MeoCardItem,
        MeoCompileHtml
    },

    data() {
        return {
            article: {}
        };
    },

    computed: {
        config() {
            let config = {
                padding: true,
            };

            if (this.article && this.article.data && this.article.data.image) {
                config.image = this.$fluro.asset.imageUrl(this.article.data.image);
            }

            return config;
        },
        title() {
            return (this.article && this.article.data && this.article.data.displayTitle) || this.article.title;
        },
        description() {
            return this.article && this.article.data && this.article.data.description || "";
        },
        text() {
            return this.article && this.article.body;
        },
        audio() {
            return this.article && this.article.data && this.article.data.audio || [];
        }
    },

    methods: {
        async init() {
            this.$meo.loading = true;

            this.article = await ResourceArticle.get(this.$route.params.id).then(({ data }) => data);
            this.$meo.loading = false;
        },
        back() {
            this.$router.push({ name: "resources" });
        },
        openAudio(item) {
            this.$refs.audioDialog.open(item);
        },
        close() {
            this.$refs.audioDialog.close();
        }
    },

    async mounted() {
        await this.init();
    }
}
</script>

<style lang="scss">
.resource {
    .fluro-image {
        border-radius: 10px;
        overflow: hidden;
    }

    @media(min-width:600px) {
        .fluro-image {
            width: 30% !important;

            &.float {
                width: 15% !important;
                float: left;
                margin-right: 15px;
                border-radius: 10px;
                overflow: hidden;
                // margin-bottom: 15px;
            }
        }
    }

    hr {
        margin: 0;
        padding: 0;
        border: 1px solid #EEEEEE;
        margin: 20px 0;
    }
}
</style>
